/*COLORS*/
$m_c: #58c747;
$sec_c: #ff694e;
$th_c: #0c274a;
$white: #fff;

/*FONTS*/
$m_f: 'Roboto';
$sec_f: 'Roboto';

//bs3 override
$btn-focus-box-shadow: none;