@import "base";
@import "../../mixins";

canvas.vc_row_canvas_anim {
  @include absoluted();
  width: 100%;
  height: 100%;
}

svg.vc_row_canvas_anim {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 170px;
  transition: opacity .3s ease;
  opacity: 0;
  &.loaded {
    opacity: 1;
  }
}

.stm_gradient_animation {
  animation: Gradient 15s ease infinite;
  background-size: 400% 400% !important;
}

.stm_container_has_video {
  overflow: hidden;
  .stm_video_iframe {
    @include absoluted();
    height: 1px;
    padding-bottom: 56.25%; /* 16:9 */
    z-index: 10;
    @include breakpoint(underLaptop) {
      transform: translateY(-25%);
    }
    iframe {
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .vc_video-bg-container {
    z-index: 30;
  }
  .stm_row-opacity {
    z-index: 20;
  }
  @include breakpoint(1100px) {
    .stm_video_iframe {
      display: none;
    }
  }
}

.stm_featured_row {
  > .vc_row {
    > .wpb_column {
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0);
      &:hover {
        box-shadow: 0 2px 7px rgba(0, 0, 0, .13);
      }
    }
  }
  .vc_column-inner {
    &:hover {
      //border-color: transparent !important;
    }
  }
  .stm_featured_box {
    position: relative;
    transform: scale(1.1);
    z-index: 10;
    .wpb_wrapper {
      transform: scale(0.95);
    }
  }
  @include breakpoint(mobile) {
    margin-left: 0 !important;
    margin-right: 0 !important;
    .stm_featured_box {
      transform: scale(1);
      .wpb_wrapper {
        transform: scale(1);
      }
    }
  }
}

.stm_row__divider_enabled {
  position: relative;
  &.stm_row__divider {
    &_saw {
      &.stm_row__divider_bottom, &.stm_row__divider_top, &.stm_row__divider_both {
        .stm_row__divider {
          position: absolute;
          left: 0;
          right: 0;
          &:after {
            content: '';
            bottom: -5px;
            display: block;
            background: linear-gradient(45deg, transparent 33.333%, #FFF 33.333%, #FFF 66.667%, transparent 66.667%),
            linear-gradient(-45deg, transparent 33.333%, #FFF 33.333%, #FFF 66.667%, transparent 66.667%);
            background-repeat: repeat-x;
            background-size: 16px 32px;
            height: 8px;
            -webkit-filter: drop-shadow(#ffffff 0px 1px 0px);
          }
        }
      }
      &.stm_row__divider_top .stm_row__divider, &.stm_row__divider_both .stm_row__divider:nth-child(1) {
        bottom: auto;
        top: -8px;
      }
      &.stm_row__divider_bottom .stm_row__divider, &.stm_row__divider_both .stm_row__divider:nth-child(2) {

        top: auto;
        bottom: -8px;
        transform: rotate(180deg);
      }
    }
  }
}

.overlap_mobile {
  z-index: 15 !important;
}

[class*=vc_container] .stm_kenburns {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  .stm_kenburns__image {
    width: 110%;
    height: 110%;
    margin-left: -5%;
    margin-top: -5%;
    animation: 60s linear stm_kenburns infinite;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@include breakpoint(tabletunder) {
  .overlap_mobile {
    .vc_row-o-equal-height {
      .vc_column-inner > .wpb_wrapper {
        height: auto !important;
      }
    }
  }
}

@keyframes stm_kenburns {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }

  50% {
    transform: scale(1.2) translateX(-10%) translateY(5%);
  }

  100% {
    transform: scale(1) translateX(0) translateY(0);
  }
}